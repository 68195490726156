import React from "react";

import { BaseBoxProps, Button, Image, Stack, Box } from "Atoms";
import { ILanguages, useLanguage } from "Providers/languageProvider/LanguageProvider";
import { languageOptions } from "../../languages";
import SweFlag from "../../assets/images/sweden.png";
import EngFlag from "../../assets/images/united-kingdom.png";

const langAndImg = {
    sv: SweFlag,
    en: EngFlag
};

type Props = {
    size?: "sm" | "md" | "lg" | "xl" | "express";
    hideSelected?: boolean;
    buttonThemeColor?: string;
} & Omit<BaseBoxProps, "size">;

const sizes = {
    sm: {
        button: {
            width: "40px",
            height: "30px"
        },
        icon: {
            width: "15px",
            height: "15px"
        }
    },
    md: {
        button: {
            width: "50px",
            height: "35px"
        },
        icon: {
            width: "20px",
            height: "20px"
        }
    },
    lg: {
        button: {
            width: "65px",
            height: "45px"
        },
        icon: {
            width: "30px",
            height: "30px"
        }
    },
    xl: {
        button: {
            width: "75px",
            height: "55px"
        },
        icon: {
            width: "35px",
            height: "35px"
        }
    },
    express: {
        button: {
            width: "85px",
            height: "65px"
        },
        icon: {
            width: "40px",
            height: "40px"
        }
    }
};

export const LanguagePicker: React.FC<Props> = ({
    size = "md",
    rounded = "md",
    hideSelected = false,
    buttonThemeColor = "gray",
    ...rest
}) => {
    const { onUserLanguageChange, userLanguage } = useLanguage();

    const button = sizes[size].button;
    const icon = sizes[size].icon;

    const mr = size === "express" ? 12 : 4;

    return (
        <Stack isInline stretch={hideSelected ? 0 : mr} h="auto" {...rest}>
            {Object.entries(languageOptions).map(([id]) => {
                const isSelected = id === userLanguage;
                const themeColor = isSelected ? "blue" : buttonThemeColor;
                //@ts-ignore
                const img = langAndImg[id];
                const shouldShow = !(hideSelected && isSelected);
                return (
                    <Box key={id}>
                        {shouldShow && (
                            <Button
                                key={id}
                                mr={hideSelected ? 0 : mr}
                                rounded={rounded}
                                themeColor={themeColor}
                                onClick={() => onUserLanguageChange(id as ILanguages)}
                                {...button}
                            >
                                <Image src={img} {...icon} />
                            </Button>
                        )}
                    </Box>
                );
            })}
        </Stack>
    );
};
