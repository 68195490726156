import { PriceType, TipsValues } from "Types";
import { DeliveryArea, PreferredPuckTab, SelectedPos } from "./types/contextTypes";
import { EatingPreference } from "Types";

export enum paymentAccountTypes {
    BANK_GIRO = "BANK_GIRO",
    POST_GIRO = "POST_GIRO",
    BANK_ACCOUNT = "BANK_ACCOUNT"
}

export const swedishWebOrderType: typeOptions = {
    BASIC: "Standard",
    CATERING_TAKE_AWAY: "Catering Takeaway",
    CATERING_DELIVERY: "Catering Hemkörning",
    EXPRESS: "Express",
    HOME_DELIVERY: "Hemleverans"
};

type typeOptions = {
    [key: string]: string;
};

export const MIDNIGHT_HOURS = 4;
export const ONLINE_TIME_PICK_INTERVAL = 15;
export const ONLINE_TIME_PICK_INTERVAL_HALF_HOUR = 30;

export enum SVGObjectType {
    TABLE = "TABLE",
    WALL = "WALL"
}

export enum NETAXEPT_PAYMENT_RESULT {
    SUCCESSFUL = "OK",
    CANCELLED = "Cancel"
}

export enum PosTypes {
    POS = "POS",
    EXPRESS = "EXPRESS",
    ONLINE = "ONLINE",
    QR_TABLE = "QR_TABLE",
    EXPRESS_ONLINE = "EXPRESS_ONLINE"
}

export const PosSwedishTypes: typeOptions = {
    POS: "Kassa",
    EXPRESS: "Expresskassa",
    ONLINE: "Onlinebeställning",
    QR_TABLE: "QR koder för bord (1000 kr ex moms)",
    EXPRESS_ONLINE: "Online Expresskassa"
};

export enum BaxiCloseReason {
    SWITCH_API_KEY = "switchingApiKey",
    SOCKET_UNMOUNT = "socketUnmount"
}

export enum DiscountType {
    FIXED_DISCOUNT = "fixedDiscounts",
    DISCOUNT = "discounts"
}

export enum BaxiTerminalStates {
    CLOSED = "CLOSED",
    READY = "READY",
    BUSY = "BUSY"
}

export enum BaxiTerminalAction {
    IDLE = "IDLE",
    PURCHASE = "PURCHASE",
    REFUND = "REFUND",
    PURCHASE_APPROVED = "PURCHASE_APPROVED",
    PURCHASE_REJECTED = "PURCHASE_REJECTED",
    ERROR = "ERROR",
    ADMIN = "ADMIN"
}

export enum BaxiConnectionErrorCodes {
    INVALID_TERMINAL_ID = 8013,
    TERMINAL_DISCONNECTED = 9100
}

export enum BaxiLocalModeResults {
    FINANCIAL_TRANSACTION_OK, // : Financial transaction OK, accumulator updated
    ADMIN_TRANSACTION_OK, // Administrative transaction OK, no update of accumulator
    TRANS_REJECTED, //2 : Transaction rejected, no update of accumulator
    TRANS_LOYALTY, //3 : Transaction is Loyalty Transaction
    UNKNOWN_RESULT //99 : Unknown result. Lost communication with terminal. Baxi has generated this local mode
}

export const FAILED_PAYMENT_ERROR_NO = 99;

export enum KitchenOrderStatus {
    DENIED = "DENIED",
    WAITING = "WAITING",
    ORDER_ADDED = "ORDER_ADDED",
    PROCESSING = "PROCESSING",
    ON_HOLD = "ON_HOLD",
    DONE_COOKING = "DONE_COOKING",
    DONE_PREPPING = "DONE_PREPPING",
    COOKING = "COOKING",
    READY_FOR_PICKUP = "READY_FOR_PICKUP",
    DELIVERED = "DELIVERED",
    CLEAR_ALL = "CLEAR_ALL",
    AUTO_CLOSED = "AUTO_CLOSED",
    ERROR_DELIVERY_CREATE = "ERROR_DELIVERY_CREATE",
    CANCELLED = "CANCELLED",
    FAILED_PAYMENT = "FAILED_PAYMENT",
    OUT_OF_STOCK = "OUT_OF_STOCK"
}

export enum ShopTableStatus {
    EMPTY = "EMPTY",
    SENT_TO_KITCHEN = "SENT_TO_KITCHEN",
    NOT_SENT_TO_KITCHEN = "NOT_SENT_TO_KITCHEN",
    PREVIEW_BILL = "PREVIEW_BILL"
}

export enum PaymentMethod {
    CARD = "CARD",
    SWISH = "SWISH",
    CASH = "CASH",
    INVOICE = "INVOICE",
    POSTPONED = "POSTPONED",
    DTADDORDER = "DTADDORDER",
    POSTPONED_EXTENDED = "POSTPONED_EXTENDED",
    MANUAL_CARD = "MANUAL_CARD",
    GIFT_CARD = "GIFT_CARD",
    ADVANCE_RECEIPT = "ADVANCE_RECEIPT",
    QOPLA_ONLINE = "QOPLA_ONLINE",
    NO_CHARGE = "NO_CHARGE"
}

export enum SwedishCateringPaymentMethod {
    CARD = "Kort",
    GIFT_CARD = "Presentkort",
    INVOICE = "Faktura",
    SWISH = "Swish",
    POSTPONED = "POSTPONED",
    DTADDORDER = "DTADDORDER",
    DTEXTENDED = "DTEXTENDED",
    MANUAL_CARD = "Manuellt kort",
    ADVANCE_RECEIPT = "Förhandsnota"
}

export enum CardType {
    AMEX = "AMEX",
    MC_VISA = "MC_VISA"
}

// TODO Move this into orderTypes
//  or somewhere within /types
export enum ReceiptType {
    PURCHASE = "PURCHASE",
    COPY = "COPY",
    TEST = "TEST",
    REFUND = "REFUND",
    OTHER_EXPENSE = "OTHER_EXPENSE",
    UNFINISHED = "UNFINISHED",
    FAILED = "FAILED",
    EMV_REFUND = "EMV_REFUND",
    CASH_INVOICE = "CASH_INVOICE",
    FAILED_BUT_PAYMENT_DONE = "FAILED_BUT_PAYMENT_DONE"
}

export enum UserRoles {
    ALL_ROLES = "ROLES_ALL",
    ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
    ROLE_QOPLA_SALE = "ROLE_QOPLA_SALE",
    ROLE_QOPLA_SUPPORT = "ROLE_QOPLA_SUPPORT",
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_USER = "ROLE_USER",
    ROLE_REPORT_ADMIN = "ROLE_REPORT_ADMIN",
    ROLE_ACCOUNTING_USER = "ROLE_ACCOUNTING_USER",
    ROLE_UMBRELLA_USER = "ROLE_UMBRELLA_USER",
    USER_ACCOUNT = "USER_ACCOUNT",
    ROLE_UMBRELLA_MULTI_COMPANY_ADMIN = "ROLE_UMBRELLA_MULTI_COMPANY_ADMIN"
}

export const userRolesConstants = {
    ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_USER: "ROLE_USER",
    ROLE_REPORT_ADMIN: "ROLE_REPORT_ADMIN"
};

export const frontyEnv = {
    DEV: "dev",
    PROD: "prod",
    DEMO: "demo",
    LOCAL: "local"
};

export const paymentTabStatusTypes = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    CANCELLED: "CANCELLED",
    FAILED: "FAILED",
    // DT should be deprecated. Please use POSTPONED in the future
    // Drive through payments should be classified as Postponed orders
    DT_CREATED: "DT_CREATED",
    DT_ONGOING: "DT_ONGOING",
    POSTPONED: "POSTPONED",
    POSTPONED_CREATED: "POSTPONED_CREATED",
    POSTPONED_EXTENDED: "POSTPONED_EXTENDED",
    POSTPONED_ONGOING: "POSTPONED_ONGOING",
    TABLE_EXTENDED: "TABLE_EXTENDED"
};

export const discountTypes = {
    RATE: "RATE",
    AMOUNT: "AMOUNT"
};

export const cookiesConstants = {
    ONLINE_SAVE_FORM_VALS: "formValues",
    ONLINE_SAVE_ME: "saveMe",
    ONLINE_ACCEPT_COOKIE: "acceptCookie",
    SELECTED_POS: "selectedPos",
    JWT_REFRESH_TOKEN: "qJwtRefreshToken",
    JWT_ACCESS_TOKEN: "qJwtAccessToken",
    Q_GROUP_ORDERING: "qGroupOrdering"
};

export const envConstants = {
    BACKEND_URL: process.env.BACKEND_URL,
    USER_ACCOUNT_SERVICE_URL: process.env.USER_ACCOUNT_SERVICE_URL,
    QREPORT_SERVICE_URL: process.env.QREPORT_SERVICE_URL,
    QREPORT_SERVICE_URL_NEW: process.env.QREPORT_SERVICE_URL_NEW,
    QANALYSIS_SERVICE_URL: process.env.QANALYSIS_SERVICE_URL,
    MERCHANT_TOKEN: process.env.MERCHANT_TOKEN,
    MERCHANT_ID: process.env.MERCHANT_ID,
    PROFILE: process.env.PROFILE,
    VERSION: process.env.VERSION,
    SENTRY_DSN: process.env.SENTRY_DSN,
    ANALYTICS_TRACKING_ID: process.env.ANALYTICS_TRACKING_ID,
    NETS_CLOUD_SOCKET_URL: process.env.NETS_CLOUD_SOCKET_URL,
    NETS_CLOUD_REST_URL: process.env.NETS_CLOUD_REST_URL,
    QSOCKET_SERVICE_URL: process.env.QSOCKET_SERVICE_URL
};

export const VAT_RATES = {
    TWELVE: 12,
    TWENTY_FIVE: 25
};

export const SIE_ACCOUNT_TYPES = {
    ONLINE_PAYMENT_METHODS: "ONLINE_PAYMENT_METHODS",
    PAYMENT_METHODS: "PAYMENT_METHODS",
    ONLINE_SALE_METHODS: "ONLINE_SALE_METHODS",
    PRODUCT_AND_CATEGORIES: "PRODUCT_AND_CATEGORIES",
    VAT: "VAT",
    TIP: "TIP"
};

export const PICKUP_DISPLAY_READY_CONSTS = [KitchenOrderStatus.READY_FOR_PICKUP, KitchenOrderStatus.DONE_COOKING];
export const PICKUP_DISPLAY_COOKING_CONSTS = [
    KitchenOrderStatus.DONE_COOKING,
    KitchenOrderStatus.COOKING,
    KitchenOrderStatus.DONE_PREPPING
];

export const onlineOrderConstants = {
    PICKUP_OPTIONS: "pickupOptions",
    UPDATED_PICKUP_OPTIONS: "updatedPickupOptions",
    TAKE_AWAY: "takeAway",
    EAT_HERE: "eatHere",
    HOME_DELIVERY: "homeDelivery",
    POST_CODE: "postCode",
    EATING_PREFERENCE: "eatingPreference",
    DELIVERY_FEE: "deliveryFee",
    DELIVERY_INFORMATION: "deliveryInformation"
};

export const eatingPreferenceToSwedish: { [key: string]: string } = {
    EAT_HERE: "Äta här",
    TAKE_AWAY: "Ta med",
    HOME_DELIVERY: "Hemleverans",
    CATERING: "Catering"
};

export const paymentStatusConstants = {
    FULLY_PAID: "FULLY_PAID",
    UNPAID: "UNPAID"
};

export const defaultExpressEatingOptions = [EatingPreference.EAT_HERE, EatingPreference.TAKE_AWAY];

export const batchStatusConstants = {
    KITCHEN_BATCH_OPEN: "OPEN",
    KITCHEN_BATCH_DONE: "DONE",
    KITCHEN_BATCH_COOKING: "COOKING",
    KITCHEN_BATCH_OPEN_AND_FULL: "OPEN_AND_FULL"
} as const;

export const csvDateFormatConstants = {
    DATEFORMAT: "YYYYMMDD_HHmm"
};

export const timeouts = {
    CALCULATECASHCHANGEMODAL_TIMEOUT: 9000
};

export enum SessionConstants {
    FOOD_OPTIONS = "FOOD_OPTIONS",
    TABLE_MODE = "TABLE_MODE",
    CHANGE_NUMBERS = "CHANGE_NUMBERS",
    BROWSE_MODE = "BROWSE_MODE",
    BACKEND_DIFF = "BACKEND_DIFF",
    QR = "QR",
    CLIENT_INFO = "CLIENT_INFO",
    GIFT_CARD_VALUES = "GIFT_CARD_VALUES",
    ALCOHOL_REF_PRODUCTS = "ALCOHOL_REF_PRODUCTS"
}

export enum PersistConstants {
    AUTHENTICATED_USER = "authenticatedUser",
    SELECTED_SHOP = "selectedShop",
    SELECTED_COMPANY = "selectedCompany",
    USER_SESSION_TTL = "userSessionTTL",
    SELECTED_TERMINAL = "selectedTerminal",
    SELECTED_POS = "selectedPos",
    ORDER_WINDOWS = "orderWindows",
    REF_PRODUCTS = "refProducts",
    ACTIVE_PAYMENT_TABS = "activePaymentTabs",
    ACTIVE_PHONE_ORDERS = "activePhoneOrders",
    PAIRED_POS_TYPE = "pairedPosType",
    FRONTEND_VERSION = "frontendVersion"
}

export const expressStartMenuItemType = {
    MENUCATEGORY: "MENUCATEGORY",
    MENUPRODUCT: "MENUPRODUCT",
    MENUBUNDLEPRODUCT: "MENUBUNDLEPRODUCT"
};

export const ORDER_DISPLAY_MINIMUM_SCREEN_WIDTH = 850;
export const ONE_MINUTE = 60000;
export const AUTO_DECLINE_MINUTES = 5;
export const LOCALHOST = "http://localhost:8092";

export const modalNames = {
    MODMODAL: "modModal",
    UPDATEMODAL: "updateModal",
    INGOINGCASHCHANGEMODAL: "ingoingCashChangeModal",
    DISCOUNTMODAL: "discountModal",
    DISCOUNTCOMBOMODAL: "discountComboModal",
    DISCOUNTPRICEMODAL: "discountModal",
    ADD_COMMENTS_MODAL: "addCommentsModal",
    RECEIPTMODAL: "receiptModal",
    ONLINE_ORDER_PICKUP_MODAL: "onlineOrderPickupModal",
    CONFIRM_ORDER_PICKUP_TIME_MODAL: "confirmOrderPickupTimeModal",
    ONLINE_INFO_MODAL: "onlineInfoModal",
    TERMS_AND_CONDITIONS_MODAL: "termsAndConditionsModal",
    POS_INVOICE_MODAL: "posInvoiceModal",
    ORDER_ADVANCED_SETTINGS_MODAL: "orderAdvancedSettingsModal",
    PHONE_ORDER_MODAL: "phoneOrderModal",
    ACTIVE_PHONE_ORDERS_MODAL: "activePhoneOrdersModal",
    CONFIRM_CANCEL_PAYMENT_MODAL: "confirmCancelPaymentModal",
    POS_ADDON_MODAL: "addonModal",
    ALLERGY_MODAL: "allergyModal",
    RESEND_CONFIRMATION_EMAIL_MODAL: "resendConfirmationEmailModal",
    SCALE_MODAL: "scaleModal",
    ONLINE_SERVICE_MODAL: "onlineServiceModal",
    USER_ACCOUNT_UNSUBSCRIBE_MODAL: "userAccountCancelSubscriptionModal",
    ABOUT_QOPLA_MODAL: "aboutQoplaModal",
    SUBSCRIPTION_INVITE_MODAL: "subscriptionInviteModal",
    OPEN_PRODUCT_MODAL: "OPEN_PRODUCT_MODAL",
    CODE_CHECK_MODAL: "CODE_CHECK_MODAL",
    CHANGE_SEATS_AMOUNT_MODAL: "changeSeatsAmountModal",
    CREATE_UMBRELLA_COMPANY_MODAL: "createUmbrellaCompanyModal",
    GROUP_ONLINE_ORDER_PAYMENT_LOCK_MODAL: "groupOnlineOrderPaymentLockModal",
    DISCOUNT_ADMIN_MODAL: "discountAdminModal",
    FORTNOX_CONNECTION_MODAL: "fortnoxConnectionModal",
    QOPLA_MESSAGE_MODAL: "qoplaMessageModal",
    POS_ADMIN_MODAL: "posAdminModal",
    EXPRESS_POS_ADMIN_MODAL: "expressPosAdminModal",
    MERGE_INVOICES_MODAL: "mergeInvoicesModal",
    EXPRESS_COMMENT_MODAL: "expressCommentModal",
    EXPRESS_CONFIRMATION_MODAL: "expressConfirmationModal",
    QR_TABLE_BATCH_MODAL: "qrTableBatchModal",
    EXPRESS_IDLE_MODAL: "expressIdleModal"
};

export const orderTypes = {
    POS: "POS",
    WEB: "WEB",
    CARDORDER: "CARDORDER",
    CASHORDER: "CASHORDER",
    SWISHORDER: "SWISHORDER",
    POSTPONEPAYMENT: "POSTPONEPAYMENT",
    EXTEND_POSTPONEPAYMENT: "EXTEND_POSTPONEPAYMENT",
    PAY_POSTPONEPAYMENT: "PAY_POSTPONEPAYMENT"
};

export const cookieDevicePairConstants = {
    POS: "selectedPos"
};

export const filetypeConstants = {
    SHOP: "shop",
    PRODUCT: "product",
    EXPRESS_START_MENU: "expressStartMenu",
    COMPANY: "company",
    EXPRESS: "express"
};

export const reportTypeConstants = {
    X: "X",
    Z: "Z",
    CASH_INVOICE: "CASH_INVOICE",
    ONLINE: "ONLINE"
};

export const terminalStepsConstants = {
    ADD_TERMINAL: "addTerminal",
    UPDATE_TERMINAL_CONFIG: "updateTerminalConfig",
    FINISHED: "finished"
};

export const printerConstants = {
    NO_CHARACTERS_PER_LINE: 42,
    NO_CHARACTERS_PER_LINE_S2: 21,
    NO_CHARACTERS_PER_LINE_S3: 46,
    EXTRA_RECEIPT_REPLACE_STRING: "@@@@@@@@@@@@@REFUND@@@@@@@@@@@@@@"
};

export const POS_PRINTER_NOT_REACHABLE_HEADER = "Kvittoskrivare ej ansluten";
export const POS_PRINTER_NOT_REACHABLE_TEXT =
    "Kan ej kontakta kvittoskrivaren \nVänligen bekräfta att den är påslagen och kontaktbar";

export const treeNodeConstants = {
    TREE_REF_PRODUCT: "TREENODE_REF_PRODUCT",
    TREE_REF_BUNDLE_PRODUCT: "TREENODE_REF_BUNDLE_PRODUCT",
    TREE_REF_CATEGORY: "TREENODE_REF_CATEGORY",
    TREE_MENU_PRODUCT: "TREENODE_MENU_PRODUCT",
    TREE_MENU_CATEGORY: "TREENODE_MENU_CATEGORY",
    TREE_MENU_BUNDLE_PRODUCT: "TREENODE_MENU_BUNDLE_PRODUCT",
    NEW_MENU_CATEGORY: "Ny menykategori",
    NEW_REF_CATEGORY: "Ny kategori",
    NEW_MENU: "Ny menu",
    NEW_COPIED_MENU: "Kopia av "
};

export const productTypes = {
    MENU_PRODUCT: "MENU_PRODUCT",
    MENU_BUNDLE_PRODUCT: "MENU_BUNDLE_PRODUCT"
};

export const modConstants = {
    MOD_SIZES: "sizes",
    MOD_FLAVOURS: "flavours",
    MOD_OTHER: "other",
    MOD_TYPES: "types"
};

export const reportConstants = {
    REPORT_ITEMS: 10,
    JOURNAL_ITEMS: 20
};

export const refundStatusConstants = {
    FULLY_REFUNDED: "FULLY_REFUNDED",
    PARTIALLY_REFUNDED: "PARTIALLY_REFUNDED",
    FAILED: "FAILED"
};

export const disableButtonsMap = {
    REFUND_BUTTON: "handleRefundOrder",
    RECEIPT_COPY_BUTTON: "receiptCopy",
    RETRY_ADD_ORDER: "retryAddOrder",
    CANCEL_REFUND: "cancelRefund"
};

export const errorConstants = {
    CONTROL_UNIT_ERROR: "FEL PÅ KONTROLLENHET",
    CASH_INVOICE: "KONTANT FAKTURA SKRIVS UT - OBS! Skriv ner kundens namn på fakturan",
    CASH_INVOICE_V2: "OBS! Skriv ner kundens namn på fakturan",
    MONEY_WITHDRAWN: "PENGAR HAR DRAGITS",
    MONEY_REFUNDED: "ÅTERBETALNING GJORD",
    CONTACT_STAFF: "TA MED KVITTO OCH KONTAKTA PERSONAL",
    UNKNOWN_ERROR: "OKÄNT FEL KONTAKTA PERSONAL",
    ALREADY_PAID: "ORDERN ÄR REDAN BETALD"
};

export const START_KDS_COLOR_COUNTDOWN_SECONDS = 900;

export const AVAILABLE_SECURITY_ROLES = [
    { label: "användare", value: UserRoles.ROLE_USER },
    { label: "qopla_sälj", value: UserRoles.ROLE_QOPLA_SALE },
    { label: "qopla_support", value: UserRoles.ROLE_QOPLA_SUPPORT },
    { label: "rapport", value: UserRoles.ROLE_ACCOUNTING_USER },
    { label: "admin", value: UserRoles.ROLE_ADMIN },
    { label: "super_admin_user", value: UserRoles.ROLE_SUPER_ADMIN },
    { label: "umbrella_multi_company_admin", value: UserRoles.ROLE_UMBRELLA_MULTI_COMPANY_ADMIN }
];

export const posCacheNames = {
    POSES: "getAllPosByShopId",
    EXPRESS_POSES: "getAllExpressByShopId"
};

export const DEFAULT_SELECTED_POS: SelectedPos = {
    id: "",
    publicId: "",
    allowPhonePostponeOrders: false,
    connectedTerminalIds: [],
    postponePaymentEnabled: false,
    cookieId: "",
    description: "",
    expressPOS: {
        startScreenHeader: null
    },
    name: "",
    onlineExpress: false,
    menuIds: [],
    puckEnabled: false,
    wasteOrderEnabled: false,
    autoRotateMenus: false,
    preferTakeAway: false,
    preferDigitalReceipts: false,
    shopId: "",
    cashChangeInputs: [],
    preferredPuckTab: PreferredPuckTab.NUM_PAD,
    sameCardRefund: false,
    receiptPrinter: {
        deviceName: "",
        ip: "",
        usbLegacyPrinter: false
    },
    posControlUnitValues: {
        productionNumber: ""
    }
};

export const DEFAULT_SELECTED_TERMINAL = {
    __typename: "Terminal",
    id: "",
    terminalId: "",
    name: "",
    shopId: "",
    description: "",
    cloudEnabled: false
};

export const DEFAULT_CONTROL_UNIT_HEARTBEAT = {
    mothershipAlive: false,
    controlUnitAlive: false
};

export const orderDisplayConstants = {
    SETTINGS_NAME: "ODCardGridSettings",
    HIDE_READY_FOR_PICKUP: "hideReadyForPickupContainer",
    HIDE_DONE_COOKING: "hideDoneCookingContainer",
    HIDE_DONE_PREPPING: "hideDonePreppingContainer",
    HIDE_CURRENT_ORDERS: "hideCurrentOrdersContainer",
    HIDE_ONLINE_ORDERS: "hideOnlineContainer",
    HIDE_CATERING_ORDERS: "hideCateringContainer",
    SHOW_CARD_COLOR: "showCardColors",
    HIDE_ORDERS: "hideOrders",
    SIDE_BAR_VISIBLE: "sideBarVisible",
    ONLY_DISPLAY_DRIVE_THROUGH: "onlyDisplayDriveThrough",
    DISPLAY_PRODUCT_DESCRIPTION: "displayProductDescriptions",
    PAUSE_ONLINE: "pauseOnline",
    HIDDEN_KITCHEN_ORDERS: "hiddenKitchenOrders",
    PLING_ON_NEW_KITCHEN_ORDER: "plingOnNewKitchenOrder",
    STORED_PAUSE_INTERVALS: "storedPauseIntervals"
};
export const displayOrderSettings = "displayOrderSettings";

export const MANUAL_URL = "https://qopla.se/manual/QManual.pdf";

export const socketTopics = {
    STOMP_HANDLER_ENDPOINT: "stompHandler",
    KITCHEN_BATCH: "kitchenBatch",
    KITCHEN_ORDER: "kitchenScreenOrders",
    PAUSE_ONLINE: "pauseOnline",
    PAUSE_HOME_DELIVERY: "pauseHomeDelivery"
};

export const qzTrayCertKey = {
    cert:
        "-----BEGIN CERTIFICATE-----\n" +
        "MIIEYzCCA0ugAwIBAgIJAIylSsXSZ31PMA0GCSqGSIb3DQEBBQUAMH0xCzAJBgNV\n" +
        "BAYTAlNFMQ8wDQYDVQQIEwZTd2VkZW4xEjAQBgNVBAcTCVN0b2NraG9sbTEOMAwG\n" +
        "A1UEChMFcW9wbGExDjAMBgNVBAsTBXFvcGxhMQowCAYDVQQDFAEqMR0wGwYJKoZI\n" +
        "hvcNAQkBFg5pbmZvQHFvcGxhLmNvbTAgFw0xOTA1MDgxMzU1MjdaGA8yMDUwMTAz\n" +
        "MTEzNTUyN1owfTELMAkGA1UEBhMCU0UxDzANBgNVBAgTBlN3ZWRlbjESMBAGA1UE\n" +
        "BxMJU3RvY2tob2xtMQ4wDAYDVQQKEwVxb3BsYTEOMAwGA1UECxMFcW9wbGExCjAI\n" +
        "BgNVBAMUASoxHTAbBgkqhkiG9w0BCQEWDmluZm9AcW9wbGEuY29tMIIBIjANBgkq\n" +
        "hkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAo3NVfY2flxjRBb9KDyf7Zgq1c29ES8aU\n" +
        "UyrnSTbwvg1fb7IGKDrsOsvO3nRWplJtLnhjW3oFbunkfnjC1EnDoCnSe/Bc8bFh\n" +
        "Pm5Zia8MTSSiO9XmAsdJW2AhqemVG3xvCw/dsgWvWf0QLdJCy2UQQApOhjK1Hw34\n" +
        "4ZisVeS3x4yWeAN+sqxQDF2AzfwxjaagIiZ6Fw/AVSDgAr63QyhcfW3Lakwu3cUR\n" +
        "GqLZJ+FoxRLEA+uzmNJ44ycBqYMIbzAZ6f3ue8wtxKTBzdzuNROMBBQcJpaUUt6h\n" +
        "dckVDZKO8enxjrwxnsPHmo6KWx3Vizz2Bd6H152UADpnMy9oEGzCLQIDAQABo4Hj\n" +
        "MIHgMB0GA1UdDgQWBBTcnpGD6h6zPwKZ6ZVytBXfsoYAyzCBsAYDVR0jBIGoMIGl\n" +
        "gBTcnpGD6h6zPwKZ6ZVytBXfsoYAy6GBgaR/MH0xCzAJBgNVBAYTAlNFMQ8wDQYD\n" +
        "VQQIEwZTd2VkZW4xEjAQBgNVBAcTCVN0b2NraG9sbTEOMAwGA1UEChMFcW9wbGEx\n" +
        "DjAMBgNVBAsTBXFvcGxhMQowCAYDVQQDFAEqMR0wGwYJKoZIhvcNAQkBFg5pbmZv\n" +
        "QHFvcGxhLmNvbYIJAIylSsXSZ31PMAwGA1UdEwQFMAMBAf8wDQYJKoZIhvcNAQEF\n" +
        "BQADggEBAIuwT6Aj67q2MVTLLmGJTerRrEuPNrns8S67LRiPrlpntWHxwbVwxz88\n" +
        "I2NQgX8TbuRgyGjwz7HuH791BBLXPx3ihJyb/gtTkLbG8gFXHOD7QeOyn2uA6eMT\n" +
        "Iia91BuZ1yGBM2N2qb7QNciWKXlJy7kcaIYEbqQwX5B5hgZ+AkcZowxp4kwR8eSv\n" +
        "FGArK6c/GXG1R8sTIwiY0uV94Mthn4ayvtTwNB1OW1mkNTZh5O+4vtQeixGvsXkE\n" +
        "/sWU+gk0pHg/wpvuM2Cora3kLHmt91HjRSoPVHbkz6m8LPB6DKTTQsNzqqSGsA2v\n" +
        "caRU/JYBbOJP7qq4T1hvfAU3GJCyz3Y=\n" +
        "-----END CERTIFICATE-----\n",
    privKey:
        "-----BEGIN RSA PRIVATE KEY-----\n" +
        "MIIEpAIBAAKCAQEAo3NVfY2flxjRBb9KDyf7Zgq1c29ES8aUUyrnSTbwvg1fb7IG\n" +
        "KDrsOsvO3nRWplJtLnhjW3oFbunkfnjC1EnDoCnSe/Bc8bFhPm5Zia8MTSSiO9Xm\n" +
        "AsdJW2AhqemVG3xvCw/dsgWvWf0QLdJCy2UQQApOhjK1Hw344ZisVeS3x4yWeAN+\n" +
        "sqxQDF2AzfwxjaagIiZ6Fw/AVSDgAr63QyhcfW3Lakwu3cURGqLZJ+FoxRLEA+uz\n" +
        "mNJ44ycBqYMIbzAZ6f3ue8wtxKTBzdzuNROMBBQcJpaUUt6hdckVDZKO8enxjrwx\n" +
        "nsPHmo6KWx3Vizz2Bd6H152UADpnMy9oEGzCLQIDAQABAoIBAQCMcC6GYEc9Vi9S\n" +
        "lteVSi8xsAXUF0hBK3CKPRTYzYHwaiEUIYw0yNvscO1gKenBopT5FquYD7HXaNPQ\n" +
        "7wXHzVFurkCSCSZy7Iu3Tq1UJbmWFuHmM5Ub0gtvnZuPGInrQfFPOeSsb0IJOezK\n" +
        "0hxeEonx3Gk32R3SfvjXLYz1iD14EC3Q9G5VeTx/kyXWik8enCMwqtRl6cBfeGp3\n" +
        "X20r2Jz6qd3GwMPh7x43nLgX59LD7FX8T/YIg1XQ1yV9AU/Ss65AVpfRhH6j3+LU\n" +
        "XmjGI+Q+JwolmGgW5OhvxY1vWG0jF2PCqnTfTAy5+xHCU4xoEI1/0uKKFCThRACZ\n" +
        "dMsJKMOpAoGBANbKmi2+/UI/Dx7aI6RBrNC9/eUbLFkbQeLMxAyYP0MbBfUf3Lhi\n" +
        "hVn19gAZAKbfmwISuNm0O7AtBLNgtHHgqcVJl8Fa3qUDT5gJ3C8bwPlvfy7OdgGX\n" +
        "qR7q5AZsZ1LLqSXOuuvxGjFwbzbtoSgLgH/inaLhhAxwylv3qDvNdeI7AoGBAMLP\n" +
        "JL5fKyFwKfqUvxzTwgKdZ6uOqxCM8iNkLcOhQVjxxc5p8EO98nSeeXCp/NmqVYij\n" +
        "Xeu9SO6uWK/KvEpJ4ODxOlKEPnv12UPledg4FFwbXQ1df5dqx/dcNrrUK2MolmXs\n" +
        "Lz7/GZjmuG1OyONPbq1KjwisIQIhVRElJ3HPfX63AoGBAMdZKHlWrkUCs6zltnin\n" +
        "f5YJD5DIhIN6+c5eT4KE9wn4FbJoTPlQbwWMczGqAuHd3EazS566WT9suNUm5rJe\n" +
        "WhLB97e797Hc/c1VMWcDcjnPEpJFil51/L+2L8YKbGb6oFV3piJ/FdlWydGIAK9i\n" +
        "5XNLxxQ6C81kDVwH4bsHOOarAoGAWmlo1DG3Ar3bvcBrMiXVVQFYq/Z6ziOb/XDx\n" +
        "DGo6udz7wODJ8aamcBlcoz1BbIPxTokDffVLc/rWjj+5Q4EmLT/HoaRem4luXGSU\n" +
        "OBVO3bCVby6FmPCl/dd+TxdJS6v6oTMMemBKsPvjfFekRFsJMsP3vj6k0yhFIr/r\n" +
        "npZ91+ECgYBTFv04p5bzIvemZt4abVfS/freSTzYIp8HWwSxY84ZWurhCWka3KvR\n" +
        "+aoDcIn0X9rovEg7n5HgLskNILJEVhi9XpBA/EXyQggzu14fzSHucVZtY39J9o+I\n" +
        "zqzxaGg13boHPHeh8x3LNQ54Au06J+Tb3cJlfuaznEvpETtl/n8Enw==\n" +
        "-----END RSA PRIVATE KEY-----\n"
};

export const paymentFunctionName = {
    DRIVE_THROUGH_PURCHASE: "payPostponedPaymentOrder",
    POS_PURCHASE: "purchasePosOrder",
    CLOUD_CARD_POS_PURCHASE: "cloudCardPosOrder"
};

export const paymentTabsHeaderTexts = {
    CANCELLED: "Avbrutet",
    CASH_INVOICE: "Godkänt - Kontantfaktura",
    APPROVED: "Godkänt"
};

export const orderUiType = {
    WEB: {
        ENG: "WEB",
        SV: "Online"
    },
    POS: {
        ENG: "POS",
        SV: "Kassa"
    }
};

export const timeConstants = {
    EMPTY_PICKUP: {
        date: "",
        time: "",
        isEarliest: false
    },
    ASAP: "asap"
};

// TODO Move this into orderTypes
//  or somewhere within /types
//  and make this an enum
type OnlineOrderStatusTypes = {
    WAITING: string;
    PENDING: string;
    CONFIRMED: string;
    DENIED: string;
    DECLINED: string;
    DELIVERED: string;
    READY_FOR_PICKUP: string;
    PROCESSING: string;
    SHOP_AUTO_DENIED: string;
    CLIENT_AUTO_DENIED: string;
    OFFLINE_AUTO_DENIED: string;
    FAILED_PAYMENT: string;
    CANCELLED_PAYMENT: string;
    ERROR_DELIVERY_CREATE: string;
    CANCELLED: string;
    OUT_OF_STOCK: string;
};

export const onlineOrderStatus: OnlineOrderStatusTypes = {
    WAITING: "WAITING",
    PENDING: "PENDING",
    CONFIRMED: "CONFIRMED",
    DENIED: "DENIED",
    SHOP_AUTO_DENIED: "SHOP_AUTO_DENIED",
    CLIENT_AUTO_DENIED: "CLIENT_AUTO_DENIED",
    OFFLINE_AUTO_DENIED: "OFFLINE_AUTO_DENIED",
    DECLINED: "DECLINED",
    DELIVERED: "DELIVERED",
    READY_FOR_PICKUP: "READY_FOR_PICKUP",
    PROCESSING: "PROCESSING",
    FAILED_PAYMENT: "FAILED_PAYMENT",
    CANCELLED_PAYMENT: "CANCELLED_PAYMENT",
    ERROR_DELIVERY_CREATE: "ERROR_DELIVERY_CREATE",
    CANCELLED: "CANCELLED",
    OUT_OF_STOCK: "OUT_OF_STOCK"
};

export const declinedOnlineOrderStatuses = [
    onlineOrderStatus.DENIED,
    onlineOrderStatus.OFFLINE_AUTO_DENIED,
    onlineOrderStatus.SHOP_AUTO_DENIED,
    onlineOrderStatus.CLIENT_AUTO_DENIED,
    onlineOrderStatus.DECLINED,
    onlineOrderStatus.CLIENT_AUTO_DENIED,
    onlineOrderStatus.ERROR_DELIVERY_CREATE,
    onlineOrderStatus.CANCELLED,
    onlineOrderStatus.OUT_OF_STOCK
];

export const failedPaymentOnlineOrderStatuses = [onlineOrderStatus.FAILED_PAYMENT, onlineOrderStatus.CANCELLED_PAYMENT];

export const failedOnlineOrderStatuses = [...declinedOnlineOrderStatuses, ...failedPaymentOnlineOrderStatuses];

export const onlineOrderStatusToSwedish: { [key: string]: string } = {
    PENDING: "Pågående",
    CONFIRMED: "Bekräftad",
    DENIED: "Nekad",
    DECLINED: "Misslyckad betalning",
    CLIENT_AUTO_DENIED: "Nekad p.g.a anslutningsfel",
    SHOP_AUTO_DENIED: "Nekad p.g.a inget svar",
    OFFLINE_AUTO_DENIED: "Nekad p.g.a offline",
    FAILED_PAYMENT: "Misslyckat försök att betala",
    CANCELLED_PAYMENT: "Användaren avbröt köp",
    CANCELLED: "Avbruten",
    DELIVERED: "Levererad"
};

export const failedReasonsToSwedish = {
    CANCELLED: "Avbruten"
};

export const qoplaColors = [
    { name: "Soft Blå Grön", color: "#3CBAC8" },
    { name: "Gelato", color: "#93EDD4" },
    { name: "Blond", color: "#F3F5C4" },
    { name: "Persika", color: "#F9CB8F" },
    { name: "Ljus Foral", color: "#F19181" },
    { name: "Kadet Grå", color: "#8E9AAF" },
    { name: "Lavender Grå", color: "#CBC0D3" },
    { name: "Drottning Rosa", color: "#EFD3D7" },
    { name: "Lavender Rådnad", color: "#FEEAFA" },
    { name: "Lavender Drake", color: "#DEE2FF" },
    { name: "extra1", color: "#8dd3c7" },
    { name: "extra2", color: "#ffffb3" },
    { name: "extra3", color: "#bebada" },
    { name: "extra4", color: "#fb8072" },
    { name: "extra5", color: "#80b1d3" },
    { name: "extra6", color: "#fdb462" },
    { name: "extra7", color: "#b3de69" },
    { name: "extra8", color: "#fccde5" },
    { name: "extra9", color: "#d9d9d9" },
    { name: "extra10", color: "#bc80bd" },
    { name: "extra11", color: "#ccebc5" },
    { name: "extra12", color: "#ffed6f" }
];

export const DAYS = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];

export const excludedGAPaths = [
    "admin/expressPosContainer",
    "admin/orderDisplay",
    "admin/pickupDisplay",
    "admin/pos",
    "express/restaurant",
    "logout",
    "stompHandler"
];

export const netsCloudResponseCodes = {
    "00": "Godkänt",
    "01": "Kontakta kortutgivare",
    "02": "Kontakta kortutgivare, vänligen vänta",
    "03": "Ogiltigt butiksnummer, butiksnummer fel",
    "04": "Beslagta kortet och kontakta utgivaren omgående",
    "05": "Medges ej",
    "06": "Tekniskt fel, kontakta kortutgivaren",
    "07": "Beslagta kortet och kontakta utgivaren omgående",
    "08": "Godkänt – Legitimation ",
    "09": "Tekniskt fel, kontakta kortutgivaren",
    "10": "Tekniskt fel, kontakta kortutgivaren",
    "11": "Godkänt",
    "12": "Ogiltig transaktion, medges ej",
    "13": "Ogiltigt belopp",
    "14": "Ogiltigt kort, kontakta kortutgivaren",
    "15": "Ej godkänt, kontakta utgivaren",
    "16": "Tekniskt fel, kontakta kortutgivaren",
    "17": "Godkänd",
    "18": "Tekniskt fel, kontakta kortutgivaren",
    "19": "Läs fel försök igen",
    "20": "Tekniskt fel",
    "21": "Medges ej",
    "22": "Tekniskt fel, kontakta kortutgivaren",
    "23": "Tekniskt fel, kontakta kortutgivaren",
    "24": "Tekniskt fel, kontakta kortutgivaren",
    "25": "Tekniskt fel, kontakta kortutgivaren",
    "26": "Tekniskt fel, kontakta kortutgivaren",
    "27": "Tekniskt fel, kontakta kortutgivaren",
    "28": "Tekniskt fel, kontakta kortutgivaren",
    "29": "Tekniskt fel, kontakta kortutgivaren",
    "30": "Fel kontakta inlösare",
    "31": "Tekniskt fel, kontakta kortutgivaren",
    "32": "Tekniskt fel, kontakta kortutgivaren",
    "33": "Beslagta kortet",
    "34": "Kortfel, kontakta utgivare",
    "35": "Tekniskt fel, kontakta kortutgivaren",
    "36": "Spärrad, beslagta kortet och kontakta kortutgivare",
    "37": "Tekniskt fel, kontakta kortutgivaren",
    "38": "Inga fler pin-kod försök",
    "39": "Tekniskt fel, kontakta kortutgivaren",
    "40": "Tekniskt fel, kontakta kortutgivaren",
    "41": "Tappat kort, beslagta kortet",
    "42": "Tekniskt fel, kontakta kortutgivaren",
    "43": "Stulet kort, beslagta kortet",
    "44": "Tekniskt fel, kontakta kortutgivaren",
    "45": "Tekniskt fel, kontakta kortutgivaren",
    "46": "Tekniskt fel, kontakta kortutgivaren",
    "47": "Tekniskt fel, kontakta kortutgivaren",
    "48": "Tekniskt fel, kontakta kortutgivaren",
    "49": "Tekniskt fel, kontakta kortutgivaren",
    "50": "Tekniskt fel, kontakta kortutgivaren",
    "51": "Ej godkänt",
    "52": "Ogiltig tjänst för detta kortet, medges ej",
    "53": "Ogiltig tjänst för detta kortet, medges ej",
    "54": "Kortets gilitighetstid har passerats",
    "55": "Fel kod",
    "56": "Kortfel kontakta utgivare, medges ej",
    "57": "Ogiltig tjänst för detta kortet, medges ej",
    "58": "Tjänst ej möjligt här",
    "59": "Tekniskt fel, kontakta kortutgivaren",
    "60": "Tekniskt fel, kontakta kortutgivaren",
    "61": "Medges ej",
    "62": "Ogiltig tjänst för detta kortet, medges ej",
    "63": "Kortfel, kontakta utgivare",
    "64": "Tekniskt fel, kontakta kortutgivaren",
    "65": "Medges ej",
    "66": "Tekniskt fel, kontakta kortutgivaren",
    "67": "Tekniskt fel, kontakta kortutgivaren",
    "68": "Tekniskt fel, kontakta kortutgivaren",
    "69": "Tekniskt fel, kontakta kortutgivaren",
    "70": "Tekniskt fel, kontakta kortutgivaren",
    "71": "Tekniskt fel, kontakta kortutgivaren",
    "72": "Tekniskt fel, kontakta kortutgivaren",
    "73": "Tekniskt fel, kontakta kortutgivaren",
    "74": "Tekniskt fel, kontakta kortutgivaren",
    "75": "Inga fler kodförsök",
    "76": "Ogiltig kansellering",
    "77": "Tekniskt fel",
    "78": "Uppdatera och försök igen",
    "79": "Tekniskt fel, kontakta kortutgivaren",
    "80": "Kortfel, kontakta utgivare",
    "81": "Kortfel, kontakta utgivare",
    "82": "Kortfel, kontakta utgivare",
    "83": "Fel sekvens, försök igen",
    "84": "Terminalfel, försök igen",
    "85": "Term. Admin. Dagsavslut",
    "86": "Duplikat, medges ej",
    "87": "Trans ej möjlig, gör dagsavslut ",
    "88": "Be kunden trycka kod",
    "89": "Be kunden trycka kod",
    "90": "Dagsavslut, försök igen",
    "91": "Ej kontakt med kortutgivare",
    "92": "Tekniskt fel, kontakta kortutgivaren",
    "93": "Ogiltig tjänst för detta kortet, Medges ej",
    "94": "Tekniskt fel, kontakta kortutgivaren",
    "95": "Tekniskt fel, gör dagsavslut",
    "96": "Tekniskt fel, kontakta inlösare",
    "97": "Tekniskt fel, tidsavbrott",
    "98": "Kortfel, kontakta utgivare",
    "99": "Kortfel, kontakta utgivare",
    "9B": "Tekniskt fel, kontakta kortutgivare",
    "9C": "Tekniskt fel, kontakta kortutgivare",
    "9D": "Tekniskt fel, kontakta kortutgivare",
    "9E": "Tekniskt fel, kontakta kortutgivare",
    "9F": "Tekniskt fel, kontakta kortutgivare",
    A0: "Kortfel, kontakta utgivare KORTET",
    A1: "Kortfel, kontakta utgivare",
    A2: "Tekniskt fel",
    A3: "Kortfel, kontakta utgivare",
    A4: "Kortfel, kontakta utgivare",
    A5: "Kortfel, kontakta utgivare",
    A6: "Kortfel, kontakta utgivare",
    A7: "Kortfel, kontakta utgivare",
    A8: "Kortfel, kontakta utgivare",
    A9: "Ej Godkänt",
    AA: "Kortfel, kontakta utgivare",
    AB: "Kortfel, kontakta utgivare",
    AC: "Medges ej, kontakta kortutgivare",
    AD: "Kortfel, kontakta utgivare ",
    AE: "Tekniskt fel, kontakta kortutgivaren ",
    AF: "Tekniskt fel, kontakta kortutgivaren ",
    B0: "Tekniskt fel",
    B1: "Medges ej, kontakta utgivare",
    B2: "Medges ej, kontakta utgivare",
    B3: "Tekniskt fel",
    B4: "Tekniskt fel, kontakta kortutgivaren",
    B5: "Tekniskt fel, kontakta kortutgivaren",
    B6: "Tekniskt fel, kontakta kortutgivaren",
    B7: "Tekniskt fel, kontakta kortutgivaren",
    B8: "Tekniskt fel, kontakta kortutgivaren",
    B9: "Tekniskt fel, kontakta kortutgivaren",
    BA: "Tekniskt fel, kontakta kortutgivaren",
    BB: "Tekniskt fel, kontakta kortutgivaren",
    BC: "Tekniskt fel, kontakta kortutgivaren",
    BD: "Tekniskt fel, kontakta kortutgivaren",
    BE: "Tekniskt fel, kontakta kortutgivaren",
    BF: "Tekniskt fel, kontakta kortutgivaren",
    C0: "Kortfel, kontakta utgivare",
    C1: "Kortfel, kontakta utgivare",
    C2: "Medges ej",
    C3: "Medges ej, kontakta utgivare",
    C4: "Tekniskt fel, kontakta kortutgivaren ",
    C5: "Tekniskt fel, kontakta kortutgivaren",
    C6: "Tekniskt fel, kontakta kortutgivaren",
    C7: "Tekniskt fel, kontakta kortutgivaren",
    C8: "Tekniskt fel, kontakta kortutgivaren",
    C9: "Tekniskt fel, kontakta kortutgivaren ",
    CA: "Tekniskt fel, kontakta kortutgivaren ",
    CB: "Tekniskt fel, kontakta kortutgivaren ",
    CC: "Tekniskt fel, kontakta kortutgivaren",
    CD: "Tekniskt fel, kontakta kortutgivaren",
    CE: "Tekniskt fel, kontakta kortutgivaren",
    CF: "Tekniskt fel, kontakta kortutgivaren ",
    D0: "Tekniskt fel",
    D1: "Tekniskt fel",
    D2: "Tekniskt fel",
    D3: "Tekniskt fel",
    D4: "Kortfel, kontakta utgivare",
    D5: "Tekniskt fel, kontakta kortutgivaren ",
    D6: "Tekniskt fel, kontakta kortutgivaren",
    D7: "Tekniskt fel, kontakta kortutgivaren",
    D8: "Tekniskt fel, kontakta kortutgivaren",
    D9: "Tekniskt fel, kontakta kortutgivaren",
    DA: "Tekniskt fel, kontakta kortutgivaren",
    DB: "Tekniskt fel, kontakta kortutgivaren",
    DC: "Tekniskt fel, kontakta kortutgivaren",
    DD: "Tekniskt fel, kontakta kortutgivaren",
    DE: "Tekniskt fel, kontakta kortutgivaren",
    DF: "Tekniskt fel, kontakta kortutgivaren",
    E0: "Tekniskt fel",
    E1: "Tekniskt fel",
    E2: "Tekniskt fel",
    E3: "Fel sekvens",
    E4: "Tekniskt fel",
    E5: "Tekniskt fel, kontakta kortutgivare, fel",
    E6: "Tekniskt fel, kontakta kortutgivare, fel",
    E7: "Tekniskt fel, kontakta kortutgivare, fel",
    E8: "Tekniskt fel, kontakta kortutgivare, fel",
    E9: "Tekniskt fel, kontakta kortutgivare, fel ",
    EA: "Tekniskt fel, kontakta kortutgivare, fel",
    EB: "Tekniskt fel, kontakta kortutgivare, fel",
    EC: "Tekniskt fel, kontakta kortutgivare, fel",
    ED: "Tekniskt fel, kontakta kortutgivare, fel",
    EE: "Tekniskt fel, kontakta kortutgivare, fel",
    EF: "Tekniskt fel, kontakta kortutgivare, fel",
    F0: "Tekniskt fel, kontakta kortutgivare, fel",
    F1: "Medges ej, kontakta kortutgivare",
    F2: "Ogiltigt KID försök igen",
    F3: "KID saknas, försök igen",
    F4: "Tekniskt fel, kontakta kortutgivare, fel ",
    F5: "Tekniskt fel, kontakta kortutgivare, fel",
    F6: "Tekniskt fel, kontakta kortutgivare, fel",
    F7: "Tekniskt fel, kontakta kortutgivare, fel",
    F8: "Tekniskt fel, kontakta kortutgivare, fel",
    F9: "Tekniskt fel, kontakta kortutgivare, fel",
    FA: "Tekniskt fel, kontakta kortutgivare, fel",
    FB: "Tekniskt fel, kontakta kortutgivare, fel",
    FC: "Tekniskt fel, kontakta kortutgivare, fel ",
    FD: "Tekniskt fel, kontakta kortutgivare, fel ",
    FE: "Tekniskt fel, kontakta kortutgivare, fel",
    FF: "Tekniskt fel, kontakta kortutgivare, fel"
};

// TODO Move this into orderTypes
//  or somewhere within /types
export enum ThirdPartyDeliveryType {
    LASTMILE = "LASTMILE",
    FOODORA = "FOODORA",
    UBEREATS = "UBEREATS",
    WOLT = "WOLT",
    BOLT = "BOLT"
}

export const THIRD_PARTY_DELIVERY_TYPE_IN_SWEDISH: any = {
    LASTMILE: "Lastmile",
    FOODORA: "Foodora",
    UBEREATS: "Uber Eats",
    WOLT: "Wolt",
    BOLT: "Bolt"
};

export const thirdPartyDeliveryNames: any = {
    FOODORA: "Foodora",
    UBEREATS: "Uber Eats",
    WOLT: "Wolt",
    BOLT: "Bolt"
};

export enum ONLINE_SERVICES {
    FOODORA = "FOODORA",
    UBEREATS = "UBEREATS",
    BOLT = "BOLT",
    WOLT = "WOLT",
    WEB = "WEB"
}

export const kagi = "vXL~uF%eLX9\\fYxP$5#D;C#HB:9,e}}";

export const oneHourInMs = 3600000;

export const messageLevelColors: {
    [key: string]: string;
} = {
    NONE: "",
    INFO: "#3498db",
    SUCCESS: "#07bc0c",
    WARNING: "#f1c40f",
    ERROR: "#e74c3c"
};

export const DEFAULT_CATERING_TIME_TO_CONFIRM = 18;

export const QOPLA_PERSONAL_KOLLEN_URL = "https://personalkollen.se/punch-clock/?vendor=qopla";

export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC"
}

export const LOCAL_STORAGE_CONSTANTS = {
    LANGUAGE: "qLanguage",
    USER_LOCALE: "userLocale",
    POS_ZOOM_LEVEL: "posZoomLevel",
    JWT_TOKEN: "jwtToken",
    AUTHENTICATED_USER: "authenticatedUser",
    POS_SETTINGS: "posSettings",
    FAVORITE_SCREEN: "favoriteScreen",
    HAS_CONNECTED_TO_SCALE: "hasConnectedToScale",
    EXPRESS_ONLINE_DISPLAY_SETTINGS: "expressOnlineDisplaySettings",
    EXPRESS_POS_DISPLAY_SETTINGS: "expressPosDisplaySettings",
    PICKUP_DISPLAY_SETTINGS: "displayOrderSettings",
    DARK_MODE: "darkMode",
    BAXI_ONGOING_ORDERS: "ongoingBaxiOrders",
    GROUP_ORDER_HAS_SENT_FEEDBACK: "groupOrderHasSentFeedback",
    ONLINE_SELECTED_PAYMENT_METHOD: "onlineSelectedPaymentMethod",
    ANONYMOUS_USER_ID: "anonymousUserId",
    ADMIN_AI_CONTENT_ASSISTANT_SETTINGS: "adminAiContentAssistantSettings",
    INVOICE_TABLE_DIMENSIONS: "invoiceTableDimensions"
};

export const FAVORITE_SCREEN = {
    ORDER_DISPLAY: "orderDisplay",
    NONE: "",
    PICKUP_SCREEN: "pickupDisplay",
    EXPRESS_ONLINE: "expressPosContainer"
};

/**
 * I argued with myself about repeating the key PRICE_PER_UNIT and the value: PRICE_PER_UNIT
 *
 * On one hand we can use Object.keys() to get the value that should be sent to the backend, but
 *  the typescript usage PRICE_TYPE_OPTIONS.TYPES_PER_UNIT.PRICE_PER_UNIT.value seems to make more sense.
 */

export const PRICE_TYPE_CATEGORIES = {
    TYPES_PER_UNIT: {
        priceTypeCategory: "unit",
        uiText: "Styckpris",
        priceTypeOptions: [
            {
                // This value is what we send to the backend
                value: PriceType.PRICE_PER_UNIT,
                uiText: "Styckpris"
            }
        ]
    },
    TYPES_PER_WEIGHT: {
        priceTypeCategory: "weight",
        uiText: "Viktpris",
        priceTypeOptions: [
            {
                // This value is what we send to the backend
                value: PriceType.PRICE_PER_GRAM,
                uiText: "g"
            },
            {
                // This value is what we send to the backend
                value: PriceType.PRICE_PER_HECTOGRAM,
                uiText: "hg"
            },
            {
                // This value is what we send to the backend
                value: PriceType.PRICE_PER_KILOGRAM,
                uiText: "kg"
            }
        ]
    }
};

export const GLOBAL_BREAK_POINTS = {
    SM: "(max-width: 30em)",
    MD: "(max-width: 48em)",
    LG: "(max-width: 62em)",
    XL: "(max-width: 80em)",
    "2XL": "(max-width: 90em)"
};

export const GLOBAL_DELIVERY_FEE_ORDER_PRODUCT_NAME = "Leveransavgift";
export const GLOBAL_FREE_REFUND_AMOUNT_PRODUCT_NAME = "Retur på fritt belopp";
export const GLOBAL_TIP_PRODUCT_NAME = "Dricks";

export const CHOPCHOP_PROD_ID = "5bfd540451fbe70001db7adf";

export const PLU_AND_DELETE_BUTTONS = [
    { value: 1, children: 1 },
    { value: 2, children: 2 },
    { value: 3, children: 3 },
    { value: 4, children: 4 },
    { value: 5, children: 5 },
    { value: 6, children: 6 },
    { value: 7, children: 7 },
    { value: 8, children: 8 },
    { value: 9, children: 9 },
    { value: "PLU", children: "PLU" },
    { value: 0, children: 0 },
    {
        value: "DEL",
        children: "DEL"
    }
];

export enum SPLIT_TYPE {
    DISHES = "DISHES",
    NUMBER = "NUMBER",
    AMOUNT = "AMOUNT"
}

export const FULL_SCREEN_JS_LICENSE_KEY = "6BDF1A6B-38594334-9BEF0558-20B7CAF5";
export const GOOGLE_RECAPTCHA_SITE_KEY = "6LdtG-0pAAAAAKceaHWYM96pi1ghs2sCvl2XBg9N";

export const ONE_DAY = 86400000;

export const frontendApplications: { [key: string]: string } = {
    FRONTY: "Fronty",
    WEB: "Web",
    ANDROID_APP: "Android",
    IOS_APP: "iOS",
    SUNMI_APP: "Sunmi"
};

export const POS_MODE = {
    SPLIT: "split",
    REGULAR: "regular"
} as const;

export enum POSReceiptType {
    NONE = "NONE",
    DIGITAL = "DIGITAL",
    PHYSICAL = "PHYSICAL"
}

export enum MANIFEST_URL {
    ADMIN = "/admin-manifest.json",
    EXPRESS = "/express-manifest.json",
    CUSTOMER = "/customer-manifest.json"
}

//this masks for numbers start with "+"
export const PHONE_NUMBER_MASKS = {
    SE: "... .. ... .. ..",
    NO: "... ... .. ...",
    FI: ".... .. ... .. .."
};

export const MAX_REFUND_ATTEMPTS = 5;

export const ONLINE_SUBSCRIPTION_CATEGORY_ID = "qopla-subscription-category";
export const ONLINE_SEARCH_CATEGORY_ID = "qopla-search-category";

export const BAD_REQUEST = "Bad Request";

export const SWISH_ERROR_MESSAGES: { [key: string]: string } = {
    ACMT01: "SWISH_ERROR_ACMT01",
    ACMT03: "SWISH_ERROR_ACMT03",
    ACMT07: "SWISH_ERROR_ACMT07",
    RF07: "SWISH_ERROR_RF07",
    BANKIDCL: "SWISH_ERROR_BANKIDCL",
    FF10: "SWISH_ERROR_FF10",
    TM01: "SWISH_ERROR_TM01",
    DS24: "SWISH_ERROR_DS24",
    BANKIDONGOING: "SWISH_ERROR_BANKIDONGOING",
    BANKIDUNKN: "SWISH_ERROR_BANKIDUNKN",
    BANKID: "SWISH_ERROR_BANKID",
    BE18: "SWISH_ERROR_BE18",
    RP06: "SWISH_ERROR_RP06",
    UNKW: "SWISH_ERROR_UNKW",
    AM21: "SWISH_ERROR_AM21",
    AM02: "SWISH_ERROR_AM02"
};
export const CAFE_HALLONBERGEN_COMPANY_ID = "5fc76f96aebe55469acbddd8";
export const RÅ_OCH_SÅ_AB_COMPANY_ID = "657aeccb1b4099706f9ada83";

export const TIPS_VALUES: TipsValues[] = [0, 5, 10, 15, 20];

export const persistedDefaultValues = {
    selectedTerminal: DEFAULT_SELECTED_TERMINAL,
    selectedPos: DEFAULT_SELECTED_POS,
    selectedCompany: {
        id: "",
        contactInformation: null,
        email: null,
        productCategoryIds: null,
        timezone: null,
        updateChangeNumber: 0,
        organisationNumber: null,
        standardProductVatRate: 0,
        name: "",
        vatRates: [],
        imageUrl: "",
        settings: {
            posEnabled: false,
            onlineEnabled: false,
            companyOnlineLandingPage: null,
            companyOnlineSettings: null,
            companySubscriptionSettings: null
        }
    },
    selectedShop: null,
    authenticatedUser: {
        companyId: null,
        shopIds: [],
        userId: null,
        username: null,
        roles: [],
        token: null
    },
    userSessionTTL: {
        ttlTimeoutMs: null
    },
    orderWindows: [],
    activePaymentTabs: {}
};

// @deprecated re-exporting as `defaultValues` for backwards compatibility - use `persistedDefaultValues` instead
export const defaultValues = persistedDefaultValues;
